import client from './client';

const newMeeting = (datas) => client.post("/newMeeting", datas);
const updateMeeting = (datas) => client.post("/updateMeeting", datas);
const getMeetingsBySalon = (datas) => client.get("/getMeetingsBySalon", datas);
const getMeetingsByEmployee = (datas) => client.get("/getMeetingsByEmployee", datas);
const cancelMeeting = (datas) => client.post("/cancelMeeting", datas);
const closeMeeting = (datas) => client.post("/closeMeeting", datas);
const noshowMeeting = (datas) => client.post("/noshowMeeting", datas);
const getMeeting = (meetingId) => client.get("/getMeeting&meetingId=" + meetingId);
const getCreditAmount = (meetingId) => client.get("/getCreditAmount");
/*const getAllInterventions = () => client.get(endpoint + 'getAllInterventions');
const updateIntervention = (datas) => client.put(endpoint + "updateIntervention", datas);
const getPOS = () => client.get("pointOfSale/getAllPointOfSales");
const getSellerPOS = () => client.get("pointOfSale/getSellerPos");
const newIntervention = (datas) => client.post(endpoint + "createIntervention", datas);
const generatePDF = (datas) => client.put(endpoint + "generateRapport2", datas);
const downloadPDF = (name) => client.get(endpoint+"downloadPDF/"+name);*/

export default
{
    newMeeting, getMeetingsBySalon, cancelMeeting, closeMeeting, noshowMeeting, getMeetingsByEmployee, updateMeeting, getMeeting, getCreditAmount
}