import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


// IMPORTS ECRANS // 
import PlanningSalon from './PlanningSalon';
import PlanningEmployee from './PlanningEmployee';
import NewMeeting from './NewMeeting';
import NewCustomer from './NewCustomer';
import CustomerLookup from './CustomerLookup';
import Customer from './CustomerScreen';
import Animal from './AnimalScreen';
import NewAnimal from './NewAnimal';
import InlineMeetingScreen from './InlineMeetingScreen'
// ------------- //

import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import { useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import { useSnackbar } from 'material-ui-snackbar-provider'
import { useEffect } from 'react';

//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import meetingApi from '../api/meeting';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import employeeApi from '../api/employee';
//  FIN DATAS // 

import { Helmet } from 'react-helmet';
import { Fragment } from 'react';


const screenHeight = window.innerHeight;


function HeaderMenu({ admin, adminView, setAdminView }) {

    const snackbar = useSnackbar();

    const [user, setUser] = useState();

    const [credit, setCredit] = useState();

    const restoreUser = async () => {
        const thisUser = await authStorage.getUser();

        if (!thisUser) return;
        setUser(thisUser);
        console.log('user:' + thisUser);
        console.log(thisUser);

    };

    const getCreditAmount = async () => {

        const response = await meetingApi.getCreditAmount();
        console.log("REPONSE");
        
        if (!response.ok) {
            console.log("Erreur solde");
            console.log(response.data);
        }
        setCredit("Crédits SMS restants: "+response.data.solde+"€");
    };

    const [dayMonth, setDayMonth] = useState("month");

    const [salons, setListSalons] = useState([]);

    const loadSalons = async () => {

        const response = await salonsApi.getSalons();
        console.log("REPONSE");
        console.log(response.data.salonFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data.salonFound);
        }

        setListSalons(response.data.salonFound);
        setCurrentScreen({ type: "salon", data: { "id": response.data.salonFound[0].id, "name": response.data.salonFound[0].name }, lastScreen: {type: currentScreen.type, data: currentScreen.data} });
    };

    const [employees, setListEmployees] = useState([]);

    const loadEmployees = async () => {

        const response = await employeeApi.getActiveEmployees();
        console.log("REPONSE");
        console.log(response.data.employeeFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        setListEmployees(response.data);
    };


    //const [currentScreen, setCurrentScreen] = useState({ type: "salon", data: { "id": salons[0].id, "name": salons[0].name } });
    const [currentScreen, setCurrentScreen] = useState({ type: "newMeeting", data: { "id": 0, "name": null }, lastScreen: null });
    const [search, setSearch] = useState("");

    const [openPanel, setOpenPanel] = useState(null);

    const [lastMeetingView, setLastMeetingView] = useState(null);

    const [selectedAnimal, setSelectedAnimal] = useState(null);
    // {type: salon/employee, date: date, id: id}

    useEffect(() => {
        loadSalons();
        loadEmployees();
        restoreUser();
        getCreditAmount();
        setInterval(getCreditAmount, 300000);
    }, []);


    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (
        <View style={styles.mainView}>
            <Helmet>
                <title>DOG MODEL</title>
            </Helmet>
            <View style={styles.navbar}>
                <View style={styles.logoContainer}>
                    <Image style={{ width: "100%", height: "100%" }} source={require("../../assets/logo-dog-model.png")} resizeMode="contain"></Image>
                </View>
                <View style={styles.shortcutContainer}>
                    <View style={styles.searchContainer}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField label="" onKeyDown={(e) => {
                                if (e.keyCode == 13) {
                                    var value = e.target.value;
                                    setCurrentScreen({ type: "customerLookup", data: { query: value }, lastScreen: {type: currentScreen.type, data: currentScreen.data} });
                                    e.target.value = "";
                                }
                            }} autoCorrect='false' placeholder='Recherche' sx={{ backgroundColor: "transparent", "& input": { backgroundColor: "transparent", color: colors.ebony } }} variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />

                        </Box>
                    </View>
                    {selectedAnimal != null ? (
                        <Fragment>
                            <Text style={{fontFamily: "poppinsSemiBold", fontSize: 12, color: colors.ebony, alignSelf: "center", marginRight: 50}}>Animal selectionné : {selectedAnimal.name} ({selectedAnimal.Customer.lastName})</Text>
                            <Button variant="contained" onClick={() => setCurrentScreen({ type: "animal", data: { "id": selectedAnimal.id, "name": selectedAnimal.name, "customerId": selectedAnimal.Customer.id, "customerName": selectedAnimal.Customer.name + " " + selectedAnimal.Customer.lastName }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.charcoal, color: "white", borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} >Retour fiche animal</Button>
                        </Fragment>
                    ) : null}
                    <Button variant="contained" onClick={() => setCurrentScreen({ type: "newCustomer", data: { "id": null, "name": null }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<AddIcon />}>Nouveau client</Button>
                    <Button variant="contained" onClick={() => { setCurrentScreen({ type: "newMeeting", data: { "id": null, "name": null }, lastScreen: {type: currentScreen.type, data: currentScreen.data} }); }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none' }} startIcon={<AddIcon />}>Nouveau rendez-vous</Button>
                </View>
            </View>
            <View style={styles.mainContainer}>
                <View style={styles.aside}>
                    <View style={styles.asideContainer}>
                        <Accordion sx={{'&:before': {display: 'none',}}} expanded={openPanel == "salon" ? true : false} onClick={()=>{openPanel != "salon" ? setOpenPanel("salon") : setOpenPanel(null)}} style={{ marginBottom: 20, boxShadow: "none", borderWidth: 0 }}>
                            <AccordionSummary
                                style={{ backgroundColor: currentScreen.type == "salon" ? colors.linen : "transparent" }}
                                expandIcon={<ExpandMoreIcon style={{ color: colors.ebony }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <CalendarIcon height={20} width={20} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />
                                <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3 }}>Planning par salon</Text>
                            </AccordionSummary>
                            <AccordionDetails style={{ boxShadow: "none", backgroundColor: "none", justifyContent: "center", alignItems: "center", alignContent: "center", textAlign: "left" }}>
                                {

                                    salons.map((item, key) => (
                                        <Button
                                            onClick={() => setCurrentScreen({ type: "salon", data: { "id": item.id, "name": item.name }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })}
                                            variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony, textTransform: 'none', width: "100%", justifyContent: "left", alignItems: "left" }}>{item.name}</Button>
                                    ))
                                }
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{'&:before': {display: 'none',}}} expanded={openPanel == "employee" ? true : false} onClick={()=>{openPanel != "employee" ? setOpenPanel("employee") : setOpenPanel(null)}} style={{ marginBottom: 20, boxShadow: "none", borderWidth: 0 }} >
                            <AccordionSummary
                                style={{ backgroundColor: currentScreen.type == "employee" ? colors.linen : "transparent" }}
                                expandIcon={<ExpandMoreIcon style={{ color: colors.ebony }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <CalendarIcon height={20} width={20} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />
                                <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3 }}>Planning par employé</Text>
                            </AccordionSummary>
                            <AccordionDetails style={{ boxShadow: "none", backgroundColor: "none", justifyContent: "center", alignItems: "center", alignContent: "center", textAlign: "left" }}>
                                {
                                    employees != [] ? employees.map((item, key) => (
                                        <Button
                                            onClick={() => setCurrentScreen({ type: "employee", data: { "id": item.id, "name": item.name }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })}
                                            variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony, textTransform: 'none', width: "100%", justifyContent: "left", alignItems: "left" }}>{item.name}</Button>
                                    )) : null
                                }
                                <Button
                                onClick={() => setCurrentScreen({ type: "employee", data: { "id": "SURBOOKING", "name": "SURBOOKING" }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })}
                                variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony, textTransform: 'none', width: "100%", justifyContent: "left", alignItems: "left" }}>{"SURBOOKING"}
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion sx={{'&:before': {display: 'none',}}} disableGutters onClick={() => {setCurrentScreen({ type: "customerLookup", data: { "id": null, "name": null }, lastScreen: {type: currentScreen.type, data: currentScreen.data} });setOpenPanel(null)}} style={{ marginBottom: 20, boxShadow: "none", borderWidth: 0 }}>
                            <AccordionSummary>
                                <ContactIcon height={20} width={20} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />
                                <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3 }}>Fiche client ou rdv</Text>
                            </AccordionSummary>
                        </Accordion>
                        <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3,textAlign: "center" }}>{credit}</Text>
                        
                        
                    </View>
                    {
                        admin == 1 ? (
                            <TouchableOpacity onPress={()=>(setAdminView(true))} style={styles.switchScreen}><Text style={{ color: "#FAFAFB", fontWeight: "bold", fontFamily: "poppinsSemiBold", }}>ESPACE ADMIN</Text></TouchableOpacity>
                        ) : null
                    }
                    
                </View >
                <ScrollView style={styles.body}>
                    {/* SCREEN */}
                    {
                        currentScreen.type == "salon" ? (<PlanningSalon lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} id={currentScreen.data.id} name={currentScreen.data.name} dateToShow={currentScreen.data.date} view={currentScreen.data.view} />)
                            :
                            currentScreen.type == "employee" ? (<PlanningEmployee lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} id={currentScreen.data.id} name={currentScreen.data.name} dateToShow={currentScreen.data.date} view={currentScreen.data.view} />)
                                :
                                currentScreen.type == "newCustomer" ? (<NewCustomer lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} id={currentScreen.data.id} name={currentScreen.data.name} />)
                                    :
                                    currentScreen.type == "newMeeting" ? (<NewMeeting selectedAnimal={selectedAnimal} setSelectedAnimal={setSelectedAnimal} lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen}  animal={currentScreen.animal} />)
                                        :
                                        currentScreen.type == "customerLookup" ? (<CustomerLookup lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} query={currentScreen.data.query} id={currentScreen.data.id} name={currentScreen.data.name} />)
                                            :
                                            currentScreen.type == "customer" ? (<Customer lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} query={currentScreen.data.query} id={currentScreen.data.id} name={currentScreen.data.name} />)
                                                :
                                                currentScreen.type == "animal" ? (<Animal selectedAnimal={selectedAnimal} setSelectedAnimal={setSelectedAnimal} lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} query={currentScreen.data.query} id={currentScreen.data.id} name={currentScreen.data.name} data={currentScreen.data} />)
                                                    :
                                                    currentScreen.type == "newAnimal" ? (<NewAnimal lastMeetingView={lastMeetingView} setLastMeetingView={setLastMeetingView} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen}  customer={currentScreen.customer} />)
                                                    : null
                    }
                    {/* FIN SCREEN */}
                </ScrollView>
            </View>
        </View>
    );
}

export default HeaderMenu;


const styles = StyleSheet.create({


    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    //FORM

    formContainer: {
        flex: 1,
        flexDirection: "row",
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 50

    },

    formColumn: {
        flex: 1,
        flexDirection: "column",
        width: "auto",

    },

    formLabelColumn: {
        flex: 1,
        flexDirection: "row",
        paddingTop: 20,

    },

    formInputsColumn: {
        width: "70%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },

    formInputsGroup: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-evenly"
    },

    inputRow: {
        flexDirection: "row",
        marginBottom: 20,
    },

    inputLabel: {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony,
        lineHeight: "100%",
        height: "100%",
    },

    separator: {
        height: 2,
        backgroundColor: colors.ebony,
        width: "100%",
        marginBottom: 50
    },

    // END FORM



    monthPicker: {
        borderColor: "#E2E2EA"
    },

    customSearchInputContainer: {
        borderColor: colors.ebony,
        borderWidth: 1,
        width: 400,
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 25,

    },

    customSearchInput: {
        borderWidth: 0,
        borderColor: "transparent",
        flex: 1,
        height: "100%",
        width: "auto",
        fontFamily: "poppinsSemiBold",
        fontWeight: "bold",
        fontSize: 14,
        color: colors.ebony,
        outlineColor: 'transparent'

    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginBottom: 20
    },


    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "column",
        flex: 1,
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "100%",
        flex: 1,
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingRight: 10,
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 10,
        paddingBttom: 10
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    screenContainerExtraMarges: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

    body: {
        marginTop: 75,
        marginLeft: 225,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 20,
        flex: 1,
    }

});
