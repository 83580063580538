import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';


import { Button, TextField, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { Fragment } from 'react';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import ReactToPrint from 'react-to-print';

import ReturnIcon from '../../assets/svg/returnIcon';
import CustomerIcon from '@mui/icons-material/PermContactCalendar';
import MeetingIcon from '@mui/icons-material/CalendarMonth';
import { IconButton } from '@mui/material';

const screenHeight = window.innerHeight;

// IMPORT SCREENS //
import InlineMeetingScreen from "./InlineMeetingScreen";
// FIN SCREENS //

//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import meetingApi from '../api/meeting';
import employeeApi from '../api/employee';
//  FIN DATAS // 

//Gestion couleurs//
const alternanceColors = [{ border: colors.blue, bg: colors.lightBlue }, { border: colors.yellow, bg: colors.egg }, { border: colors.red, bg: colors.lightPink }];
//Gestions couleurs//


import generateNewPdf from '../utils/pdfSalon';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function PlanningSalon({ lastMeetingView, setLastMeetingView, id, name, dateToShow, view, currentScreen, setCurrentScreen }) {



    const [salonId, setSalonId] = useState(id);
    const [meetingList, setMeetingList] = useState([]);
    const [popUp, setPopUp] = useState(false);
    const [popUpData, setPopUpData] = useState(null);
    const [employees, setListEmployees] = useState([]);


    const loadEmployees = async () => {
    
        const response = await employeeApi.getAvailableEmployeeBySalons({ salonId: id, date: moment(currentDate).format("YYYY-MM-DD") });
        console.log("REPONSE");
        console.log(response.data);
        if (!response.ok) {
            console.log("test");
            console.log(response.data);
        }

        var employeeList = [];

        if (response.error) {

        }
        else if (response.data != []) {
            response.data.forEach(function (item, key) {
                employeeList.push({ id: item.Employee.id, name: item.Employee.name, ampm: item.nb == 1 ? item.ampm : "day" });
                console.log({ id: item.Employee.id, name: item.Employee.name, ampm: item.nb == 1 ? item.ampm : "day" });
            });
        }

        setListEmployees(employeeList);
    
    };

    const getMeetingList = async () => {
        const meetings = await meetingApi.getMeetingsBySalon({ "salonId": id, "date": currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + String(currentDate.getDate()).padStart(2, '0'), "period": dayMonth });
        //alert(id);
        console.log(meetings.data);
        //POUR SALONS, GROUP BY : 
        var surbooking = [];
        var result = meetings.data.reduce(function (r, a) {
            console.log(a);
            if (a.surbooking == 0) {
                r[a.Employee.name] = r[a.Employee.name] || [];
                r[a.Employee.name].push(a);
            }
            else {
                surbooking.push(a);
            }

            return r;
        }, Object.create(null));

        result["SURBOOKING"] = surbooking;
        console.log("GROUP BY :");
        console.log(result);


        setMeetingList(result);
    }




    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "dateObject": date.toISOString(), "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay() - 1];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const generatePdf = (action) => {
        generateNewPdf(meetingList, currentDate, name, action);
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState(view ? view : "month");
    const [currentDate, setCurrentDate] = useState(dateToShow ? new Date(dateToShow) : new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    var workingTime = ["7:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(15, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);


    const setDuration = function (meetings, type) {
        var durationMorning = 0.0;
        var durationAfternoon = 0.0;
        var durationDay = 0.0;

        meetings.forEach(function (element) {
            var time = new Date(element.datePlanned).getUTCHours();
            durationDay += element.duration + element.aditionalTime;
        });

        return moment.utc(moment.duration(durationDay, "hours").as('milliseconds')).format('HH:mm');

    }

    const generateDayEmployee = function (value, date) {
        //VALUE = 0-JULIE 1-[...RDV]
        var thisDayEmployee = [];
        value[1].map(function (element, index) {
            if (new Date(element.datePlanned).toLocaleDateString('fr-FR') == date.completeDate) {
                thisDayEmployee.push(element);
            }
        });

        console.log(thisDayEmployee);
        if (thisDayEmployee.length > 0) {
            return (
                <View style={styles.employeeDay}>
                    <View style={styles.gridEmployeeName}><Text style={styles.gridEmployeeLabel}>{value[0]}</Text></View>
                    <TouchableOpacity onPress={() => {
                        updateDates("change", new Date(date.dateObject)); setDayMonth("day"); console.log("RESULTAT HEURES");
                    }} style={[styles.gridBox, styles.gridBorder, { width: "50%" }]}><Text style={{ fontFamily: "poppinsSemiBold" }}>{setDuration(thisDayEmployee)}</Text></TouchableOpacity>
                </View>
            );
        }
        else {
            return (
                <View style={styles.employeeDay}>

                </View>
            );
        }
    }

    var dates = getDates(currentDate);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
        setLastMeetingView({ type: "salon", id: id, date: currentDate, name: name });
        loadEmployees();
        getMeetingList();
        
    }, [currentDate, dayMonth, id, name, popUp]);




    return (
        <ScrollView style={styles.body}>
            <View style={styles.screenTitle}>
                <Text style={styles.pageTitle}>{name}</Text>
                <Text style={styles.titleSeparator}>|</Text>
                <Text style={styles.pageAction}>Planning</Text>
            </View>
            <View style={styles.screenContainer}>
                <View style={styles.screenControllers}>
                    <View style={[styles.anchorLeftControllers, { zIndex: 300 }]}>
                        <Button variant="contained" onClick={() => { currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : null }} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                    </View>
                    <View style={styles.centeredControllers}>
                        <Text style={styles.controllerTitle}>Sélectionner la période</Text>
                        <View style={styles.buttonGroup}>
                            <Button variant="contained" onClick={() => { if (dayMonth == "day") { setDayMonth("month"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "month" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none', marginRight: 5 }}>Mois</Button>
                            <Button variant="contained" onClick={() => { if (dayMonth == "month") { setDayMonth("day"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "day" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none' }}>Jour</Button>
                        </View>
                    </View>
                    {
                        dayMonth == "day" ? (
                            <View style={styles.anchorRightControllers}>
                                <Button variant="contained" onClick={() => (generatePdf("open"))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.ebony, color: "#FFFFFF", borderRadius: 7, textTransform: 'none', marginRight: 20 }}>Exporter en PDF</Button>
                                <Button variant="contained" onClick={() => (generatePdf("print"))} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.smokedWhite, color: colors.ebony, borderRadius: 7, textTransform: 'none' }}>Imprimer</Button>
                            </View>
                        ) : null
                    }

                </View>
                <View style={styles.screenControllers}>
                    <View style={styles.centeredControllers}>
                        {dayMonth == "month" ? <Text style={styles.pageTitle}>{currentDateString.long}</Text> : (
                            <View>
                                <Button style={{ backgroundColor: 'transparent', fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, padding: 0 }} endIcon={<ExpandMoreIcon />} onClick={() => setDatePickerOpened(datePickerOpened => !datePickerOpened)} >{currentDateString.long}</Button>
                                <MobileDatePicker
                                    sx={{ datepicker: { fontFamily: "poppins" } }}
                                    format='MMMM dd, yyyy'
                                    label="Selectionner une date"
                                    variant="static"
                                    value={currentDate}
                                    open={datePickerOpened}
                                    onOpen={() => setDatePickerOpened(true)}
                                    onClose={() => setDatePickerOpened(false)}
                                    onChange={(newValue) => {
                                        setCurrentDate(new Date(newValue));
                                        setDatePickerOpened(false)
                                    }}
                                    componentsProps={{
                                        actionBar: {
                                            // The actions will be the same between desktop and mobile
                                            actions: [""]
                                        }
                                    }}
                                    disableOpenPicker={true}
                                    renderInput={(params) => null}
                                />
                            </View>
                        )}

                        <View style={styles.anchorRightControllers}>
                            <ButtonGroup variant="outlined" aria-label="outlined button group">
                                <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("remove", 1)}>
                                    <KeyboardArrowLeftIcon />
                                </Button>
                                <Button disabled={true} style={{ borderColor: "#E2E2EA", color: colors.ebony, fontFamily: "poppins" }}>{currentDateString.short}</Button>
                                <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("add", 1)}>
                                    <KeyboardArrowRightIcon />
                                </Button>
                            </ButtonGroup>
                        </View>
                    </View>
                </View>
                {dayMonth == "day" ? (<Text style={{ width: "100%", color: "#000000", textAlign: "center", marginBottom: 10 }}>{employees.length == 0 ? "Aucun employé ne travaille ce jour à "+name+"." : "Employés travaillant ce jour à "+name+": " + employees.map(item => item.name).join(', ') }</Text>) : null }
                <Text style={{ width: "100%", backgroundColor: colors.red, color: "#ffffff", textAlign: "center", marginBottom: 20 }}>CONFORMÉMENT À VOTRE CONTRAT DE TRAVAIL, VOUS DEVEZ PRENDRE UNE PAUSE REPAS D'AU MOINS 1 HEURE.</Text>
                {popUp ? <View style={[styles.inlineMeeting]}><InlineMeetingScreen data={popUpData} popUp={popUp} setPopUp={setPopUp} currentScreen={currentScreen} setCurrentScreen={setCurrentScreen} /></View> : null}
                {
                    dayMonth == "month" ?
                        (
                            <View style={styles.monthCalendar}>
                                {
                                    Object.keys(dates).map((dayColumn) => (
                                        <View style={styles.dayColumn}>
                                            <View style={styles.dayTitle}><Text style={{ color: colors.mist, fontFamily: "PoppinsSemiBold", textTransform: 'uppercase' }}>{dayColumn}</Text></View>
                                            {
                                                dates[dayColumn].map((date) => (
                                                    <View style={[styles.dateBox]} onClick={() => { if (date.completeDate != null)  {updateDates("change", new Date(date.dateObject)); setDayMonth("day");}  }}>
                                                        {date.completeDate ? (
                                                            <Fragment>
                                                                <View style={styles.periodLabel}>
                                                                    <View style={[styles.gridBox, { width: "50%", alignItems: "left" }]}><Text style={styles.dayNumber}>{date.date}</Text></View>
                                                                    <View style={[styles.gridBox, { width: "50%" }]}><Text style={styles.gridEmployeeLabel}>Temps vendu</Text></View>
                                                                </View>
                                                                {

                                                                    meetingList == [] ? null : Object.entries(meetingList).map((value, key) => (

                                                                        <Fragment>
                                                                            {
                                                                                generateDayEmployee(value, date)
                                                                            }
                                                                        </Fragment>
                                                                    ))
                                                                }

                                                            </Fragment>
                                                        ) : null}
                                                    </View>
                                                ))
                                            }
                                        </View>
                                    ))
                                }
                            </View>
                        ) :
                        (
                            <View style={styles.dayCalendar}>
                                <View style={styles.hoursGrid}>
                                    {
                                        timesList.map((value, key) => (
                                            <View style={key == timesList.length - 1 ? [styles.hourRow, { borderBottomColor: "transparent", backgroundColor: key % 2 === 0 ? colors.iron : 'transparent' }] : [styles.hourRow, { backgroundColor: key % 2 === 0 ? colors.iron : 'transparent' }]}>
                                                <Text style={styles.hourText}>{value}</Text>
                                            </View>
                                        ))
                                    }
                                    <View style={styles.eventGrid}>
                                        {meetingList == [] ? null : Object.entries(meetingList).map((value, key) => (
                                            <View style={styles.eventColumn}>
                                                <View style={[styles.eventHeader, { backgroundColor: alternanceColors[key % 3].border }]}><Text style={styles.eventHeaderTitle}>{value[0]}</Text></View>
                                                <View style={styles.eventContent}>
                                                    {
                                                        value[1].map((element, index) => (

                                                            <View style={[styles.eventBox, { borderColor: element.noShow == 1 ? "#ff0000" : alternanceColors[key % 3].border, backgroundColor: alternanceColors[key % 3].bg, padding: element.duration + element.aditionalTime == 0.25 ? 0 : "10px 20px", height: (element.duration + element.aditionalTime) * 100, top: (((new Date(element.datePlanned).getUTCHours() * 60 + new Date(element.datePlanned).getUTCMinutes()) - (7 * 60)) / 60) * 100 }]}>
                                                                {
                                                                    element.duration + element.aditionalTime > 0.5 ? (
                                                                        <View style={[styles.eventRow]}>
                                                                            <View style={styles.eventText}>
                                                                                <Text style={styles.eventTextTitle}>{element.Customer.lastName} / {element.Animal.race} / {element.Animal.name} / {element.Prestation.name}</Text>
                                                                                {
                                                                                    element.surbooking == 1 ? (
                                                                                        <Text style={styles.eventTextTitle}>/ {element.Employee.name}</Text>
                                                                                    ) : null
                                                                                }
                                                                            </View>
                                                                        </View>
                                                                    ) : (<View style={styles.eventRow}>
                                                                        <View style={[styles.eventText, { paddingTop: 14, paddingBottom: 0 }]}>
                                                                            <Text style={styles.eventTextTitle}>{element.Customer.lastName} / {element.Animal.race} / {element.Animal.name} / {element.Prestation.name}</Text>
                                                                            {
                                                                                element.surbooking == 1 ? (
                                                                                    <Text style={styles.eventTextTitle}>/ {element.Employee.name}</Text>
                                                                                ) : null
                                                                            }
                                                                        </View>
                                                                    </View>)
                                                                }
                                                                {
                                                                    element.duration + element.aditionalTime == 0.25 ?
                                                                        (
                                                                            <View style={[styles.eventButtons, { top: 0 }]}>
                                                                                <IconButton onClick={() => { setPopUp(false); setCurrentScreen({ type: "customer", data: { "id": element.customerId, "name": { "name": element.Customer.name, "lastName": element.Customer.lastName } }, lastScreen: { type: currentScreen.type, data: currentScreen.data } }); }} variant="text" style={{ padding: 0, boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: alternanceColors[key % 3].border, color: colors.ebony, borderRadius: 25, textTransform: 'none', width: 25 }}><CustomerIcon fontSize="small" /></IconButton>
                                                                                <IconButton onClick={() => { setPopUp(true); setPopUpData(element); }} variant="text" style={{ padding: 0, boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", marginLeft: 10, fontSize: 10, backgroundColor: alternanceColors[key % 3].border, color: colors.ebony, borderRadius: 25, textTransform: 'none', width: 25 }} ><MeetingIcon fontSize="small" /></IconButton>
                                                                            </View>
                                                                        )
                                                                        :
                                                                        (
                                                                            <View style={styles.eventButtons}>
                                                                                <IconButton onClick={() => { setPopUp(false); setCurrentScreen({ type: "customer", data: { "id": element.customerId, "name": { "name": element.Customer.name, "lastName": element.Customer.lastName } }, lastScreen: { type: currentScreen.type, data: currentScreen.data } }); }} variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: alternanceColors[key % 3].border, color: colors.ebony, borderRadius: 25, textTransform: 'none', width: 25, height: 25 }} size="small"><CustomerIcon fontSize="small" /></IconButton>
                                                                                <IconButton onClick={() => { setPopUp(true); setPopUpData(element); }} variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", marginLeft: 10, fontSize: 10, backgroundColor: alternanceColors[key % 3].border, color: colors.ebony, borderRadius: 25, textTransform: 'none', width: 25, height: 25 }} size="small" ><MeetingIcon fontSize="small" /></IconButton>
                                                                            </View>
                                                                        )
                                                                }

                                                            </View>
                                                        ))
                                                    }
                                                </View>

                                            </View>
                                        ))
                                        }

                                    </View>
                                </View>


                            </View>
                        )
                }

            </View>
        </ScrollView>
    );
}

export default PlanningSalon;

const styles = StyleSheet.create({

    inlineMeeting: {
        zIndex: 200,
        flex: 1,
        flexDirection: "row",
        top: 0,
        width: "100%",
        position: "absolute",
        marginTop: 100
    },

    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    monthPicker: {
        borderColor: "#E2E2EA"
    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        position: "absolute",
        top: 0,
        width: "100%",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        position: "absolute",
        right: 20,
        top: 10,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 25,
        borderTopWidth: 1,
        borderColor: colors.charcoal,
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    anchorLeftControllers: {
        flexDirection: "row",
        position: "absolute",
        left: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "row",
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "25%",
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        minHeight: 150,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingBottom: 10
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 5,
        paddingBttom: 5
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },



});
