import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import moment, { calendarFormat } from 'moment';

import { Button, Input, TextField, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EventNoteIcon from '@mui/icons-material/EventNoteOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { fr } from 'date-fns/locale'
//import contactIcon from '../../assets/contactIcon.svg';
//import planningIcon from '../../assets/planningIcon.svg';
import { style } from '@mui/system';
import { Fragment } from 'react'; 
import { TouchableWithoutFeedback } from 'react-native';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';


const screenHeight = window.innerHeight;



function LayoutScreen({ route, navigation }) {

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Lundi"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay()];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("day");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    var workingTime = ["8:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);

    var dates = getDates(currentDate);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    }, [currentDate, dayMonth]);


    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (
        <View style={styles.mainView}>
            <View style={styles.navbar}>
                <View style={styles.logoContainer}>
                    <Image style={{ width: "100%", height: "100%" }} source={require("../../assets/logo-dog-model.png")} resizeMode="contain"></Image>
                </View>
                <View style={styles.shortcutContainer}>
                    <View style={styles.searchContainer}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField id="input-with-sx" label="Rechercher" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                        </Box>
                    </View>
                    <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none' }} startIcon={<AddIcon />}>Nouveau rendez-vous</Button>
                </View>
            </View>
            <View style={styles.mainContainer}>
                <View style={styles.aside}>
                    <View style={styles.asideContainer}>
                        <Accordion style={{ marginBottom: 20, boxShadow: "none", borderWidth: 0 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon style={{ color: colors.ebony }} />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{
                                    backgroundColor: colors.linen
                                }}
                            >
                                <CalendarIcon height={20} width={20} style={{fill: colors.ebony, marginRight: 10}} resizeMode="contain"/>
                                <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3 }}>Planning par salon</Text>
                            </AccordionSummary>
                            <AccordionDetails style={{ boxShadow: "none", backgroundColor: "none", justifyContent: "center", alignItems: "center",  alignContent: "center", textAlign: "left"}}>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony,  textTransform: 'none',  width: "100%", justifyContent: "left", alignItems: "left" }}>Brignais</Button>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony,  textTransform: 'none',  width: "100%", justifyContent: "left", alignItems: "left" }}>Vourles</Button>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony,  textTransform: 'none',  width: "100%", justifyContent: "left", alignItems: "left" }}>Francheville</Button>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ marginBottom: 20, boxShadow: "none", borderWidth: 0 }}>
                            
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon style={{ color: colors.ebony }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                <CalendarIcon height={20} width={20} style={{fill: colors.ebony, marginRight: 10}} resizeMode="contain"/>
                                <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3 }}>Planning par employé</Text>
                            </AccordionSummary>
                            <AccordionDetails style={{ boxShadow: "none", backgroundColor: "none", justifyContent: "center", alignItems: "center",  alignContent: "center", textAlign: "left"}}>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony,  textTransform: 'none',  width: "100%", justifyContent: "left", alignItems: "left" }}>Julie</Button>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony,  textTransform: 'none', width: "100%", justifyContent: "left", alignItems: "left" }}>Gaelle</Button>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: 'transparent', color: colors.ebony,  textTransform: 'none',  width: "100%", justifyContent: "left", alignItems: "left" }}>Francheville</Button>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ marginBottom: 20, boxShadow: "none", borderWidth: 0 }}>
                            <AccordionSummary>
                            <ContactIcon height={20} width={20} style={{fill: colors.ebony, marginRight: 10}} resizeMode="contain"/>
                                <Text style={{ fontFamily: "poppinsSemiBold", fontSize: 10, color: colors.ebony, marginTop: 3 }}>Fiche client ou rdv</Text>
                            </AccordionSummary>
                        </Accordion>
                        
                        <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 7, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }} startIcon={<AddIcon />}>Nouveau rdv</Button>
                        <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 7, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }} startIcon={<AddIcon />}>Nouveau client</Button>
                    </View>
                    <View style={styles.switchScreen}><Text style={{ color: "#FAFAFB", fontWeight: "bold", fontFamily: "poppinsSemiBold", }}>ESPACE ADMIN</Text></View>
                </View >
                <ScrollView style={styles.body}>
                    <View style={styles.screenTitle}>
                        <Text style={styles.pageTitle}>BRIGNAIS</Text>
                        <Text style={styles.titleSeparator}>|</Text>
                        <Text style={styles.pageAction}>Planning</Text>
                    </View>
                    <View style={styles.screenContainer}>
                        <View style={styles.screenControllers}>
                            <View style={styles.centeredControllers}>
                                <Text style={styles.controllerTitle}>Sélectionner la période</Text>
                                <View style={styles.buttonGroup}>
                                    <Button variant="contained" onClick={() => { if (dayMonth == "day") { setDayMonth("month"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "month" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none', marginRight: 5 }}>Mois</Button>
                                    <Button variant="contained" onClick={() => { if (dayMonth == "month") { setDayMonth("day"); updateDates("change", currentDate) } else null }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: dayMonth == "day" ? colors.cashmere : colors.linen, color: colors.ebony, borderRadius: 7, textTransform: 'none' }}>Jour</Button>
                                </View>
                            </View>
                            <View style={styles.anchorRightControllers}>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.ebony, color: "#FFFFFF", borderRadius: 7, textTransform: 'none', marginRight: 20 }}>Exporter en PDF</Button>
                                <Button variant="contained" style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.smokedWhite, color: colors.ebony, borderRadius: 7, textTransform: 'none' }}>Imprimer</Button>
                            </View>
                        </View>
                        <View style={styles.screenControllers}>
                            <View style={styles.centeredControllers}>

                                {dayMonth == "month" ? <Text style={styles.pageTitle}>{currentDateString.long}</Text> : (
                                    <View>
                                        <Button style={{ backgroundColor: 'transparent', fontFamily: "poppinsSemiBold", textTransform: "uppercase", fontWeight: "bold", fontSize: 20, color: colors.ebony, padding: 0 }} endIcon={<ExpandMoreIcon />} onClick={() => setDatePickerOpened(datePickerOpened => !datePickerOpened)} >{currentDateString.long}</Button>
                                        <MobileDatePicker
                                            sx={{ datepicker: { fontFamily: "poppins" } }}
                                            format='MMMM dd, yyyy'
                                            label="Selectionner une date"
                                            variant="static"
                                            value={currentDate}
                                            open={datePickerOpened}
                                            onOpen={() => setDatePickerOpened(true)}
                                            onClose={() => setDatePickerOpened(false)}
                                            onChange={(newValue) => {
                                                setCurrentDate(new Date(newValue));
                                            }}
                                            renderInput={(params) => null}
                                        />
                                    </View>
                                )}

                                <View style={styles.anchorRightControllers}>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("remove", 1)}>
                                            <KeyboardArrowLeftIcon />
                                        </Button>
                                        <Button disabled={true} style={{ borderColor: "#E2E2EA", color: colors.ebony, fontFamily: "poppins" }}>{currentDateString.short}</Button>
                                        <Button style={{ borderColor: "#E2E2EA", color: colors.ebony, paddingLeft: 5, paddingRight: 5 }} onClick={() => updateDates("add", 1)}>
                                            <KeyboardArrowRightIcon />
                                        </Button>
                                    </ButtonGroup>
                                </View>
                            </View>
                        </View>
                        {
                            dayMonth == "month" ?
                                (
                                    <View style={styles.monthCalendar}>
                                        {
                                            Object.keys(dates).map((dayColumn) => (
                                                <View style={styles.dayColumn}>
                                                    <View style={styles.dayTitle}><Text style={{ color: colors.mist, fontFamily: "PoppinsSemiBold", textTransform: 'uppercase' }}>{dayColumn}</Text></View>
                                                    {
                                                        dates[dayColumn].map((date) => (
                                                            <View style={[styles.dateBox]}>
                                                                {date.completeDate != null ? (
                                                                    <Fragment>
                                                                        <Text style={styles.dayNumber}>{date.date}</Text>
                                                                        <View style={styles.periodLabel}>
                                                                            <View style={styles.gridEmployeeName}></View>
                                                                            <View style={styles.gridBox}><Text style={styles.gridEmployeeLabel}>M</Text></View>
                                                                            <View style={styles.gridBox}><Text style={styles.gridEmployeeLabel}>A</Text></View>
                                                                        </View>
                                                                        <View style={styles.employeeDay}>
                                                                            <View style={styles.gridEmployeeName}><Text style={styles.gridEmployeeLabel}>JULIE</Text></View>
                                                                            <View style={[styles.gridBox, styles.gridBorder]}></View>
                                                                            <View style={[styles.gridBox, styles.gridBorder]}></View>
                                                                        </View>
                                                                    </Fragment>
                                                                ) : null}
                                                            </View>
                                                        ))
                                                    }
                                                </View>
                                            ))
                                        }
                                    </View>
                                ) :
                                (
                                    <View style={styles.dayCalendar}>
                                        <View style={styles.hoursGrid}>
                                            {
                                                timesList.map((value, key) => (
                                                    <View style={key == timesList.length - 1 ? [styles.hourRow, { borderBottomWidth: 0, borderBottomColor: "transparent" }] : styles.hourRow}>
                                                        <Text style={styles.hourText}>{value}</Text>
                                                    </View>
                                                ))
                                            }
                                            <View style={styles.eventGrid}>
                                                <View style={styles.eventColumn}>
                                                    <View style={styles.eventHeader}><Text style={styles.eventHeaderTitle}>JULIE</Text></View>
                                                    <View style={styles.eventContent}>
                                                        <View style={styles.eventBox}>
                                                            <View style={styles.eventRow}>

                                                            </View>
                                                            <View style={styles.eventButtons}>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120, height: 25 }}  size="small" endIcon={<ChevronRightIcon />}>Fiche client</Button>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", marginLeft: 10, fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120,  height: 25 }} size="small" endIcon={<ChevronRightIcon />}>Voir rdv</Button>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={styles.eventColumn}>
                                                    <View style={styles.eventHeader}><Text style={styles.eventHeaderTitle}>JULIE</Text></View>
                                                    <View style={styles.eventContent}>
                                                        <View style={styles.eventBox}>
                                                            <View style={styles.eventRow}>

                                                            </View>
                                                            <View style={styles.eventButtons}>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120, height: 25 }}  size="small" endIcon={<ChevronRightIcon />}>Fiche client</Button>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", marginLeft: 10, fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120,  height: 25 }} size="small" endIcon={<ChevronRightIcon />}>Voir rdv</Button>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={styles.eventColumn}>
                                                    <View style={styles.eventHeader}><Text style={styles.eventHeaderTitle}>JULIE</Text></View>
                                                    <View style={styles.eventContent}>
                                                        <View style={styles.eventBox}>
                                                            <View style={styles.eventRow}>

                                                            </View>
                                                            <View style={styles.eventButtons}>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120, height: 25 }}  size="small" endIcon={<ChevronRightIcon />}>Fiche client</Button>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", marginLeft: 10, fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120,  height: 25 }} size="small" endIcon={<ChevronRightIcon />}>Voir rdv</Button>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                                <View style={styles.eventColumn}>
                                                    <View style={styles.eventHeader}><Text style={styles.eventHeaderTitle}>JULIE</Text></View>
                                                    <View style={styles.eventContent}>
                                                        <View style={styles.eventBox}>
                                                            <View style={styles.eventRow}>
                                                                <View style={styles.eventText}>
                                                                    <Text style={styles.eventTextTitle}>Nom :</Text>
                                                                    <Text style={styles.eventTextContent}>VERDET</Text>
                                                                </View>
                                                            </View>
                                                            <View style={styles.eventButtons}>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120, height: 25 }}  size="small" endIcon={<ChevronRightIcon />}>Fiche client</Button>
                                                                <Button variant="contained" style={{ boxShadow: "none", lineHeight: 10, fontFamily: "poppinsSemiBold", marginLeft: 10, fontSize: 10, backgroundColor: colors.yellow, color: colors.ebony, borderRadius: 25,  textTransform: 'none', width: 120,  height: 25 }} size="small" endIcon={<ChevronRightIcon />}>Voir rdv</Button>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        
                                    </View>
                                )
                        }

                    </View>
                </ScrollView >
            </View>
        </View>
    );
}

export default LayoutScreen;

const styles = StyleSheet.create({


    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    monthPicker: {
        borderColor: "#E2E2EA"
    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,    
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader : {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "row",
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "25%",
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingBottom: 10
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 5,
        paddingBttom: 5
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

    body: {
        marginTop: 75,
        marginLeft: 225,
        paddingTop: 20,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 20,
        flex: 1,
    }

});
