import { useFonts } from 'expo-font';
import { Image, StyleSheet, Text, TouchableOpacity, View, ScrollView } from 'react-native';
import React, { useEffect, useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import moment, { calendarFormat } from 'moment';
import ReturnIcon from '../../assets/svg/returnIcon';

import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { Button, Input, TextField, ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EventNoteIcon from '@mui/icons-material/EventNoteOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import colors from '../utils/colors';
import { fr } from 'date-fns/locale'
//import contactIcon from '../../assets/contactIcon.svg';
//import planningIcon from '../../assets/planningIcon.svg';
import { style } from '@mui/system';
import { Fragment } from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import CalendarIcon from '../../assets/svg/calendarIcon';
import ContactIcon from '../../assets/svg/contactIcon';
import DogIcon from '../../assets/svg/dogIcon';
import ClientIcon from '../../assets/svg/clientIcon';
import { TextInput } from 'react-native';
import { TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'material-ui-snackbar-provider';


//IMPORT DATAS // 
import salonsApi from '../api/salons';
import customerApi from '../api/customer';
import animalApi from '../api/animal';
import prestaApi from '../api/presta';
import authStorage from '../auth/storage';
import AuthContext from '../auth/context';
import customer from '../api/customer';

//  FIN DATAS // 

const screenHeight = window.innerHeight;


function NewAnimal({ currentScreen, setCurrentScreen, customer }) {

    const [listSalons, setListSalons] = useState([]);

    const loadSalons = async () => {

        const response = await salonsApi.getSalons();
        console.log("REPONSE");
        console.log(response.data.salonFound);
        if (!response.ok) {
            console.log("test");
            console.log(response.data.salonFound);
        }

        setListSalons(response.data.salonFound);
    };

    const hoursList = [
        "00:15", "00:30", "00:45",
        "01:00", "01:15", "01:30", "01:45",
        "02:00", "02:15", "02:30", "2:45",
        "03:00", "03:15", "03:30", "3:45",
        "04:00"
    ];

    const snackbar = useSnackbar();

    var getDates = function (thisDate) {
        console.log(thisDate);
        var year = thisDate.getFullYear();
        var month = thisDate.getMonth();
        var date = new Date(year, month, 1);
        console.log(year, month);
        var names = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
        var result = { "": [], "lun": [], "mar": [], "mer": [], "jeu": [], "ven": [], "sam": [], "dim": [] };
        var firstDay = names[date.getDay()];
        for (var i = 0; i < names.length; i++) {
            if (firstDay == names[i] && firstDay != "dim") break;
            if (names[i] != "dim") result[names[i]].push({ "completeDate": null });
        }

        while (date.getMonth() == month) {

            var element = { "completeDate": date.toLocaleDateString('fr-FR'), 'date': date.getDate(), "day": names[date.getDay()] };
            result[element.day].push(element);
            date.setDate(date.getDate() + 1);


        }
        return result;
    }


    const getCurrentDateString = function (type) {
        var days = ["Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche", "Lundi"];
        var months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        var day = days[currentDate.getDay()];
        var month = months[currentDate.getMonth()];
        if (dayMonth == "day" && type == "long") {
            return day + " " + currentDate.getDate() + " " + month + " " + currentDate.getFullYear();
        }
        else {
            return month + " " + currentDate.getFullYear();
        }
    }

    const updateDates = function (type, value) {

        console.log("-------------");
        console.log(currentDate);
        console.log(dayMonth);

        if (type === "change") {
            setCurrentDate(value);
        }
        if (type == "add") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + value)));
        }
        if (type == "remove") {
            setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - value)))
        }

    }

    const [value, setValue] = useState(null);
    const [dayMonth, setDayMonth] = useState("day");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [currentDateString, setCurrentDateString] = useState({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
    const [datePickerOpened, setDatePickerOpened] = useState(false);

    const emptyCustomerForm = { id: customer.id, animal: { name: null, type: "dog", race: null, remise: "0", presta: { name: null, durationDate: "00:15", comments: null, price: null } } };
    const [newCustomer, setNewCustomer] = useState(emptyCustomerForm);

    const validateForm = async () => {
        if (newCustomer.animal.name == null) snackbar.showMessage("L'animal doit avoir un nom.");
        else {
                const customerId = customer.id;
                if (newCustomer.animal.name != null) {
                    newCustomer.animal.customerId = customerId;
                    console.log(newCustomer);
                    const animalResponse = await animalApi.newAnimal(newCustomer.animal);
                    console.log(animalResponse);
                    if (!animalResponse.ok) snackbar.showMessage(animalResponse.error);
                    else { // ANIMAL CREE
                        const animalId = animalResponse.data.id;
                        if (newCustomer.animal.presta.name != null) {
                            newCustomer.animal.presta.animalId = animalId;
                            newCustomer.animal.presta.duration = moment.duration(newCustomer.animal.presta.durationDate).asHours();
                            const prestaResponse = await prestaApi.newPrestation(newCustomer.animal.presta);
                            if (!prestaResponse.ok) snackbar.showMessage(prestaResponse.error);
                            else {
                                setCurrentScreen({ type: "animal", data: { "id": animalId, "name": newCustomer.animal.name, "customerId": customerId, "customerName": customer.name + " " + customer.lastName }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })
                                
                            }
                        }
                        else {
                            setCurrentScreen({ type: "animal", data: { "id": animalId, "name": newCustomer.animal.name, "customerId": customerId, "customerName": customer.name + " " + customer.lastName }, lastScreen: {type: currentScreen.type, data: currentScreen.data} })
                        }


                    }
            }

        }
    };

    var workingTime = ["8:00", "20:00"];


    var times = function (hours) {
        var timeList = [];
        var startingHour = hours[0];
        var lastHour = hours[1];
        var date = moment("01/01/2001 " + startingHour);

        console.log(date.format("HH:mm"));

        while (date.format("HH:mm") != lastHour) {
            timeList.push(date.format("HH:mm"));
            date.add(60, "m");
        }

        timeList.push(date.format("HH:mm"))

        return timeList;
    };

    const timesList = times(workingTime);

    var dates = getDates(currentDate);

    useEffect(() => {
        setCurrentDateString({ "short": getCurrentDateString("short"), "long": getCurrentDateString("long") });
        loadSalons();
    }, [currentDate, dayMonth, newCustomer]);


    const [loaded] = useFonts({
        poppins: require('../fonts/Poppins-Regular.ttf'),
        poppinsMedium: require('../fonts/Poppins-Medium.ttf'),
        poppinsSemiBold: require('../fonts/Poppins-SemiBold.ttf'),
        poppinsBold: require('../fonts/Poppins-Bold.ttf')
    });

    if (!loaded) {
        return null;
    }



    return (
        <ScrollView style={styles.body}>
            <View style={[styles.screenTitle, { backgroundColor: colors.red }]}>
                <Text style={[styles.pageTitle, { color: colors.milk }]}>CREER UN NOUVEL ANIMAL</Text>
            </View>
            <View style={styles.screenContainerExtraMarges}>
                <View style={[styles.anchorLeftControllers, {zIndex: 300}]}>
                        <Button variant="contained"  onClick={() => {currentScreen.lastScreen !== undefined && currentScreen.lastScreen !== null ? setCurrentScreen({ type: currentScreen.lastScreen.type, data: currentScreen.lastScreen.data }) : null}} style={{ boxShadow: "none", fontFamily: "poppins", fontSize: 12, backgroundColor: "transparent", color: colors.ebony, borderRadius: 7, marginTop: 5, marginBottom: 5, textTransform: 'none', marginRight: 20 }} startIcon={<ReturnIcon height={25} width={30} style={{ fill: colors.ebony, marginRight: 10 }} resizeMode="contain" />}>Retour</Button>
                </View>
                <View style={[styles.formContainer,{flex: 'unset', marginTop: 50}]}>
                    <View style={styles.formColumn}>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Prénom client</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TextField
                                    value={customer.name}
                                    disabled
                                    fullWidth label="" autoCorrect='false' placeholder='Nom du client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                            </View>
                        </View>
                    </View>
                    <View style={[styles.formColumn, { marginLeft: 50 }]}>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Nom client</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TextField
                                    value={customer.lastName}
                                    disabled
                                    fullWidth label="" autoCorrect='false' placeholder='Nom du client' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.separator}></View>

                <View style={styles.formContainer}>
                    <View style={styles.formColumn}>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Nom animal</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TextField
                                    value={newCustomer.animal.name ? newCustomer.animal.name : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            name: value.target.value
                                        }
                                    })))}
                                    fullWidth label="" autoCorrect='false' placeholder="Nom de l'animal" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                            </View>
                        </View>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Type animal</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <Select
                                    fullWidth
                                    outlineColor="transparent"
                                    sx={{
                                        "& fieldset": { border: 'none' },
                                    }}
                                    value={newCustomer.animal.type ? newCustomer.animal.type : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            type: value.target.value
                                        }
                                    })))}
                                    disableUnderline={true}
                                    displayEmpty
                                    IconComponent={(props) => (
                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                    )}
                                >
                                    <MenuItem value="dog">
                                        Chien
                                    </MenuItem>
                                    <MenuItem value="cat">
                                        Chat
                                    </MenuItem>
                                </Select>
                            </View>
                        </View>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}><Text style={styles.inputLabel}>Race animal</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TextField
                                    value={newCustomer.animal.race ? newCustomer.animal.race : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            race: value.target.value
                                        }
                                    })))}
                                    fullWidth label="" autoCorrect='false' placeholder="Race de l'animal" variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                            </View>
                        </View>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Remise</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <Select
                                    fullWidth
                                    outlineColor="transparent"
                                    sx={{
                                        "& fieldset": { border: 'none' },
                                    }}
                                    value={newCustomer.animal.remise ? newCustomer.animal.remise : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            remise: value.target.value
                                        }
                                    })))}
                                    disableUnderline={true}
                                    displayEmpty
                                    IconComponent={(props) => (
                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                    )}
                                >
                                    <MenuItem value="0">0%</MenuItem>
                                    <MenuItem value="0.05">5%</MenuItem>
                                    <MenuItem value="0.1">10%</MenuItem>
                                    <MenuItem value="0.15">15%</MenuItem>
                                    <MenuItem value="0.20">20%</MenuItem>
                                </Select>
                            </View>
                        </View>

                    </View>
                    <View style={[styles.formColumn, { marginLeft: 50 }]}>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Prestation</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <Select
                                    fullWidth
                                    sx={{
                                        "& fieldset": { border: 'none' },
                                    }}
                                    value={newCustomer.animal.presta.name ? newCustomer.animal.presta.name : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            presta: {
                                                ...prevState.animal.presta,
                                                name: value.target.value
                                            }
                                        }
                                    })))}
                                    disableUnderline={true}
                                    displayEmpty
                                    IconComponent={(props) => (
                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                    )}

                                >
                                    <MenuItem value="Bain">Bain</MenuItem>
                                    <MenuItem value="Bain Retouche">Bain Retouche</MenuItem>
                                    <MenuItem value="Brossage">Brossage</MenuItem>
                                    <MenuItem value="Chat">Chat</MenuItem>
                                    <MenuItem value="Coupe">Coupe</MenuItem>
                                    <MenuItem value="Coupe NL">Coupe NL</MenuItem>
                                    <MenuItem value="Coupe P">Coupe P</MenuItem>
                                    <MenuItem value="Epilation">Epilation</MenuItem>
                                    <MenuItem value="Pré Toilettage">Pré Toilettage</MenuItem>
                                    <MenuItem value="Retouche">Retouche</MenuItem>
                                    <MenuItem value="Self">Self</MenuItem>
                                    <MenuItem value="Temps passé">Temps passé</MenuItem>
                                    <MenuItem value="Tonte">Tonte</MenuItem>
                                </Select>
                            </View>
                        </View>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Temps</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                {/*<TimePicker
                                        fullWidth
                                        ampm={false}
                                        label=""
                                        minutesStep={15}
                                        placeholder="Temps"
                                        minTime={moment().startOf('day')}
                                        maxTime={moment().startOf('day').add(4, "hours")}
                                        value={newCustomer.animal.presta.durationDate ? newCustomer.animal.presta.durationDate : moment().startOf('day')}
                                            onChange={(value)=>(setNewCustomer((prevState) => ({
                                                  ...prevState,
                                                  animal: {
                                                      ...prevState.animal,
                                                      presta : {
                                                        ...prevState.animal.presta,
                                                        durationDate: value
                                                      }
                                                      
                                                  }
                                        })))}
                                        renderInput={(params) => <TextField sx={{width: "100%", backgroundColor: colors.smokedWhite, paddingLeft: "30px", borderRadius: 25, border: "none", "& fieldset":{border: "none"}}} {...params}  />}
                                    />*/}
                                <Select
                                    fullWidth
                                    sx={{
                                        "& fieldset": { border: 'none' },
                                    }}
                                    value={newCustomer.animal.presta.durationDate ? newCustomer.animal.presta.durationDate : "00:15"}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            presta: {
                                                ...prevState.animal.presta,
                                                durationDate: value.target.value
                                            }

                                        }
                                    })))}
                                    disableUnderline={true}
                                    displayEmpty
                                    IconComponent={(props) => (
                                        <ExpandMoreIcon {...props} style={{ marginRight: 10 }} />
                                    )}

                                >
                                    {hoursList.map((item, key) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </View>
                        </View>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Infos presta.</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TextField
                                    value={newCustomer.animal.presta.comments ? newCustomer.animal.presta.comments : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            presta: {
                                                ...prevState.animal.presta,
                                                comments: value.target.value
                                            }
                                        }
                                    })))}
                                    fullWidth label="" multiline rows={4} autoCorrect='false' placeholder='Informations complémentaires' variant="standard" InputProps={{ disableUnderline: true }} InputLabelProps={{ shrink: false }} />
                            </View>
                        </View>
                        <View style={styles.inputRow}>
                            <View style={styles.formLabelColumn}>
                                <Text style={styles.inputLabel}>Tarif</Text>
                            </View>
                            <View style={styles.formInputsColumn}>
                                <TextField
                                    value={newCustomer.animal.presta.price ? newCustomer.animal.presta.price : ""}
                                    onChange={(value) => (setNewCustomer((prevState) => ({
                                        ...prevState,
                                        animal: {
                                            ...prevState.animal,
                                            presta: {
                                                ...prevState.animal.presta,
                                                price: value.target.value
                                            }
                                        }
                                    })))}
                                    endAdornment={<InputAdornment position="end">€</InputAdornment>}
                                    fullWidth label="" type="number" min autoCorrect='false' placeholder='Prix de la prestation' variant="standard" InputProps={{ disableUnderline: true, inputProps: { min: 1 } }} InputLabelProps={{ shrink: false }} />
                            </View>
                        </View>

                    </View>
                </View>

                <View style={styles.centeredControllers}>
                    <Button variant="contained" onClick={() => { validateForm() }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", marginRight: 50, fontSize: 12, backgroundColor: colors.blue, color: colors.ebony, borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>CONFIRMER</Button>
                    <Button variant="contained" onClick={() => { setNewCustomer(emptyCustomerForm); snackbar.showMessage('Formulaire réinitialisé.') }} style={{ boxShadow: "none", fontFamily: "poppinsSemiBold", fontSize: 12, backgroundColor: colors.red, color: "#FFFFFF", borderRadius: 25, marginBottom: 20, textTransform: 'none', width: 180, alignSelf: "center", height: 50 }}>ANNULER</Button>

                </View>
            </View>
        </ScrollView>
    );
}

export default NewAnimal;


const styles = StyleSheet.create({

    anchorLeftControllers: {
        flexDirection: "row",
        position: "absolute",
        left: 15,
    },


    mainView: {
        backgroundColor: colors.smokedWhite,
        flex: 1,
    },

    //FORM

    formContainer: {
        flex: 1,
        flexDirection: "row",
        marginRight: 50,
        marginLeft: 50,
        marginBottom: 50

    },

    formColumn: {
        flex: 1,
        flexDirection: "column",
        width: "auto",

    },

    formLabelColumn: {
        flex: 1,
        flexDirection: "row",
        paddingTop: 20,

    },

    formInputsColumn: {
        width: "70%",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20
    },

    formInputsGroup: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "space-evenly"
    },

    inputRow: {
        flexDirection: "row",
        marginBottom: 20,
    },

    inputLabel: {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony,
        lineHeight: "100%",
        height: "100%",
    },

    separator: {
        height: 2,
        backgroundColor: colors.ebony,
        width: "100%",
        marginBottom: 50
    },

    // END FORM



    monthPicker: {
        borderColor: "#E2E2EA"
    },

    customSearchInputContainer: {
        borderColor: colors.ebony,
        borderWidth: 1,
        width: 400,
        justifyContent: "space-around",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 25,

    },

    customSearchInput: {
        borderWidth: 0,
        borderColor: "transparent",
        flex: 1,
        height: "100%",
        width: "auto",
        fontFamily: "poppinsSemiBold",
        fontWeight: "bold",
        fontSize: 14,
        color: colors.ebony,
        outlineColor: 'transparent'

    },

    eventText: {
        flex: 1,
        flexDirection: "row",
        wdith: "100%",
        marginBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: "rgba(0, 0, 0, 0.1)",
        paddingTop: 5,
        paddingBottom: 5
    },

    eventTextTitle: {
        fontFamily: "poppinsSemiBold",
        marginRight: 10
    },

    eventGrid: {
        flex: 1,
        width: "100%",
        flexDirection: "row",
        top: 0,
        paddingLeft: 70,
        position: "absolute"

    },

    eventRow: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    eventColumn: {
        flex: 1,
        flexDirection: "column",
        paddingLeft: 5,
        paddingRight: 5
    },

    eventHeader: {
        height: 40,
        borderRadius: 25,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: colors.yellow,
        marginBottom: 20
    },

    eventHeaderTitle: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16
    },

    eventContent: {
        flex: 1,
        flexDirection: "column",
    },

    eventBox: {
        backgroundColor: colors.egg,
        borderWidth: 4,
        borderColor: colors.yellow,
        borderRadius: 10,
        height: 100,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20
    },

    eventButtons: {
        flex: 1,
        flexDirection: "row",
    },

    hourText: {
        textAlign: "center",
        width: 60,
        fontFamily: "poppinsSemiBold"
    },

    hoursGrid: {
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 60,
        position: "relative",
    },

    hourRow: {
        height: 100,
        borderBottomWidth: 1,
        borderColor: "#e0e0e0",
        justifyContent: "center",

    },

    screenControllers: {
        marginBottom: 40,
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center"
    },

    centeredControllers:
    {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        marginBottom: 20
    },


    controllerTitle: {
        fontSize: 12,
        fontFamily: "poppinsSemiBold",
        textAlignVertical: "center",
        marginTop: 8,
        marginRight: 10
    },

    buttonGroup:
    {
        flexDirection: "row",
        borderRadius: 7,
        padding: 2,
        backgroundColor: colors.milk
    },

    anchorRightControllers: {
        flexDirection: "row",
        position: "absolute",
        right: 15,
    },

    monthCalendar: {
        width: "100%",
        flex: 1,
        zIndex: 50,
        flexDirection: "row"
    },

    periodLabel: {
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center"
    },

    employeeDay: {
        flexDirection: "column",
        flex: 1,
        marginBottom: 2,
    },

    gridEmployeeName: {
        flex: 1
    },

    gridEmployeeLabel:
    {
        fontFamily: "poppinsSemiBold",
        color: colors.ebony
    },

    gridBox: {
        width: "100%",
        flex: 1,
        alignItems: "center"
    },

    gridBorder: {
        borderWidth: 0.5,
        borderColor: colors.charcoal
    },

    dayColumn:
    {
        flex: 1,
    },

    dayTitle:
    {
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: 50,
        backgroundColor: colors.smokedWhite,
        marginBottom: 20
    },

    dateBox:
    {
        height: 100,
        borderWidth: 1,
        borderColor: colors.charcoal,
        paddingLeft: 10,
        paddingRight: 10,
    },

    dayNumber: {
        paddingLeft: 10,
        fontFamily: "poppins",
        color: colors.mist,
        fontSize: 12,
        paddingTop: 10,
        paddingBttom: 10
    },

    navbar: {
        height: 75,
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderBottomColor: "#E2E2EA",
        borderBottomWidth: 2,
        flexDirection: "row",
        position: "fixed",
        zIndex: 10
    },

    switchScreen: {
        backgroundColor: colors.ebony,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center"
    },

    screenContainer: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flex: 1
    },

    screenContainerExtraMarges: {
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        width: "100%",
        paddingLeft: 50,
        paddingRight: 50,
        paddingTop: 50,
        paddingBottom: 50,
        flex: 1
    },

    categoryButton: {
        backgroundColor: colors.linen,
        width: "100%",
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 0
    },

    logoContainer: {
        width: 225,
        height: "100%",
        padding: 5,
        alignItems: "center",
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1
    },

    shortcutContainer: {
        flex: 1,
        flexDirection: "row",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10
    },

    searchContainer: {
        flex: 1
    },

    screenTitle: {
        borderRadius: 5,
        width: "100%",
        height: 50,
        backgroundColor: colors.linen,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },

    titleSeparator: {
        marginLeft: 20,
        marginRight: 20,
        fontSize: 20,
        color: "#D8D8D8"
    },

    pageAction: {
        fontSize: 18,
        fontWeight: "600",
        color: colors.mist
    },

    pageTitle: {
        fontFamily: "poppinsSemiBold",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        color: colors.ebony
    },

    searchInput: {
        width: 300
    },

    mainContainer: {
        flex: 1,
        flexDirection: "row"
    },

    aside: {
        paddingTop: 20,
        marginTop: 75,
        width: 225,
        height: screenHeight - 75,
        backgroundColor: colors.snow,
        borderRightColor: "#E2E2EA",
        borderRightWidth: 1,
        position: "fixed",
        zIndex: 10
    },

    asideContainer: {
        flex: 1,
    },

});
